import { BaseInterface } from "./base";

export type MassageUserType = {
  id: string;
  name: string;
};

export type MassageProgramType = {
  id: string;
  name: string;
};

export interface MassageHistoryInterface extends BaseInterface {
  _id?: string;
  user: MassageUserType;
  program: MassageProgramType;
  rating: number;
  deviceSerialNumber: string;
  deviceName: string;
  startDate: Date;
  endDate: Date;
  massageTime: number;
}

export interface CreateMassageHistoryRequestInterface {
  program: string;
  startDate: number;
  endDate: number;
  rating?: number;
  deviceSerialNumber: string;
  deviceName: string;
}

export interface MassageHistoryFilteringDataInterface {
  "user.name"?: string;
  "user.id"?: string;
  "program.name"?: string;
  deviceSerialNumber?: string;
  "endDate[gte]"?: string;
  "endDate[lte]"?: string;
  offset?: number;
  limit?: number;
}

export enum MassageHistoryFilterTypesEnum {
  PROGRAM_NAME = "program.name",
  USER_NAME = "user.name",
  DEVICE_SERIAL_NUMBER = "deviceSerialNumber",
  END_DATE_GTE = "endDate[gte]",
  END_DATE_LTE = "endDate[lte]",
}
