import _ from "lodash";
import { GOOGLE_MAPS_KEY } from "app/keys/keys";

type Props = {
  coordinates: string[] | number[];
};

const Map: React.FC<Props> = ({ coordinates }) => {
  return (
    <>
      {!_.isEmpty(coordinates) && (
        <div className="map-box">
          <iframe
            title="address"
            src={`https://www.google.com/maps/embed/v1/view?key=${GOOGLE_MAPS_KEY}&center=${coordinates[0]},${coordinates[1]}&zoom=13`}
            width="100%"
            height="200"
            frameBorder="0"
            style={{ border: 0 }}
          ></iframe>
        </div>
      )}
    </>
  );
};

export default Map;
