import moment from "moment";
import AppButton, { ButtonColorTypes } from "common/components/AppButton";
import AppDatePicker from "common/components/AppDatePicker";
import AppInput, { InputTypes } from "common/components/AppInput";
import LabeledContainer from "common/components/LabeledContainer";
import { ReportFilteringDataInterface, ReportFilterTypesEnum } from "../../../../shared/interfaces/report";

type Props = {
  filteringData: ReportFilteringDataInterface;
  onChange: React.Dispatch<React.SetStateAction<ReportFilteringDataInterface>>;
};

const IssueFilters: React.FC<Props> = ({ filteringData, onChange }) => {
  const handleChangingSingleField = (fieldName: ReportFilterTypesEnum, value: string): void => {
    const filterDataTmp = { ...filteringData };
    filterDataTmp[fieldName] = value;
    onChange(filterDataTmp);
  };

  const handleClearFilters = (): void => {
    onChange({});
  };

  const handleFilterByDate = (date: Date): void => {
    const filterDataTmp = { ...filteringData };
    filterDataTmp[ReportFilterTypesEnum.CREATED_AT_GTE] = moment(date).startOf("day").unix().toString();
    filterDataTmp[ReportFilterTypesEnum.CREATED_AT_LTE] = moment(date).endOf("day").unix().toString();
    onChange(filterDataTmp);
  };

  const currentEndDateValue = (): Date | undefined => {
    if (filteringData["createdAt[gte]"]) {
      return new Date(parseInt(filteringData["createdAt[gte]"]) * 1000);
    }
    return undefined;
  };

  return (
    <div className="pb-3" id="filtry">
      <div className="col-12 col-md-4 col-lg-3 d-flex w-100 gap-2">
        <div className="form-group col-12 col-lg-2">
          <LabeledContainer title="Użytkownik">
            <AppInput
              inputType={InputTypes.TEXT}
              value={filteringData["userName"]}
              onChange={(text: string) => {
                handleChangingSingleField(ReportFilterTypesEnum.USER_NAME, text);
              }}
            />
          </LabeledContainer>
        </div>
        <div className="form-group col-12 col-lg-2">
          <LabeledContainer title="E-mail">
            <AppInput
              inputType={InputTypes.TEXT}
              value={filteringData["userEmail"]}
              onChange={(text: string) => {
                handleChangingSingleField(ReportFilterTypesEnum.USER_EMAIL, text);
              }}
            />
          </LabeledContainer>
        </div>
        <div className="form-group col-12 col-lg-2">
          <LabeledContainer title="Numer urządzenia">
            <AppInput
              inputType={InputTypes.TEXT}
              value={filteringData.deviceSerialNumber}
              onChange={(text: string) => {
                handleChangingSingleField(ReportFilterTypesEnum.DEVICE_SERIAL_NUMBER, text);
              }}
            />
          </LabeledContainer>
        </div>
        <div className="form-group col-12 col-lg-2">
          <LabeledContainer title="Data zgłoszenia">
            <AppDatePicker
              value={currentEndDateValue()}
              onChange={(date: Date) => {
                handleFilterByDate(date);
              }}
            />
          </LabeledContainer>
        </div>
        <div className="float-right mt-4 ms-auto">
          <AppButton title="Usuń filtry" onClick={handleClearFilters} color={ButtonColorTypes.LIGHT} />
        </div>
      </div>
    </div>
  );
};

export default IssueFilters;
