import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import {} from "react-router-dom";
import _ from "lodash";
import { StatusCodeEnum } from "../../../shared/interfaces/auth";
import { logout } from "common/utils/logout";

export const getAuthToken = (): string | null => localStorage.getItem("token");
export const baseUrl = "https://api-neuronum.overcloud.usermd.net/";
// export const baseUrl = "http://localhost:3001";

export const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => {
    const token = getAuthToken();
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const customBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const result = await baseQuery(args, api, extraOptions);

  if (_.get(result, "error.status") === StatusCodeEnum.CODE_401) {
    logout();
  }
  return result;
};
