import { AppRoutesEnum } from "common/types";
import { useCheckAuthQuery } from "features/login/loginApi";
import { useEffect, useState } from "react";
import { useOutlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../../common/components/Header";

const App = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const outlet = useOutlet();
  const location = useLocation();
  const navigate = useNavigate();
  useCheckAuthQuery();

  useEffect(() => {
    if (location.pathname === "/") {
      navigate(AppRoutesEnum.Reports);
    }
  }, [location]);

  return (
    <div id="main-wrapper" className={`${showMenu && "show-sidebar"}`}>
      <Header showMenu={showMenu} setShowMenu={setShowMenu} />
      {outlet}
    </div>
  );
};

export default App;
