import { createApi } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "app/apiConfig";
import {
  BaseCreateResponseInterface,
  BaseDocumentResponseInterface,
  BaseListResponseInterface,
  BaseSuccessResponse,
} from "../../../../shared/interfaces/base";
import {
  CreateMassageRequestInterface,
  DeleteMassageRequestInterface,
  MassageFilteringDataInterface,
  MassageInterface,
  UpdateMassageRequestInterface,
} from "../../../../shared/interfaces/massage";

export const massageApi = createApi({
  reducerPath: "massageApi",
  baseQuery: customBaseQuery,
  tagTypes: ["Massages", "Massage"],
  endpoints: (build) => ({
    createMassage: build.mutation<BaseCreateResponseInterface<MassageInterface>, CreateMassageRequestInterface>({
      query: (data) => ({
        url: `massage/massage`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Massages"],
    }),
    updateMassage: build.mutation<BaseCreateResponseInterface<MassageInterface>, UpdateMassageRequestInterface>({
      query: (data) => ({
        url: `massage/massage/${data._id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Massages"],
    }),
    getMassages: build.query<BaseListResponseInterface<MassageInterface>, MassageFilteringDataInterface>({
      query: (query) => ({
        url: `massage/massage`,
        params: query,
      }),
      providesTags: ["Massages"],
    }),
    getMassage: build.query<BaseDocumentResponseInterface<MassageInterface>, string>({
      query: (id) => ({
        url: `massage/massage/${id}`,
      }),
      providesTags: ["Massage"],
    }),
    deleteMassage: build.mutation<BaseSuccessResponse, DeleteMassageRequestInterface>({
      query: ({ id }) => ({
        url: `massage/massage/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useCreateMassageMutation,
  useLazyGetMassagesQuery,
  useLazyGetMassageQuery,
  useUpdateMassageMutation,
  useDeleteMassageMutation,
} = massageApi;
