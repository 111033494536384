import FavIcon from "../../assets/images/fav-icon.svg";
import PlayIcon from "../../assets/images/play-icon.svg";
import ClockIcon from "../../assets/images/clock-icon.svg";
import StepsIcon from "../../assets/images/steps-icon.svg";

export enum UserStatIconEnum {
  Fav,
  Play,
  Clock,
  Steps,
}

type Props = {
  icon: UserStatIconEnum;
  children: React.ReactNode;
};

const UserStatContainer: React.FC<Props> = ({ icon, children }) => {
  const renderIcon = () => {
    switch (icon) {
      case UserStatIconEnum.Fav:
        return FavIcon;
      case UserStatIconEnum.Play:
        return PlayIcon;
      case UserStatIconEnum.Clock:
        return ClockIcon;
      case UserStatIconEnum.Steps:
        return StepsIcon;
      default:
        return FavIcon;
    }
  };
  return (
    <div className="stat fav-program">
      <img src={renderIcon()} alt="tile icon" className="tile-icon" />
      {children}
    </div>
  );
};

export default UserStatContainer;
