import { ErrorInterface } from "./base";
import { UserInterface } from "./user";

export enum StatusCodeEnum {
  CODE_401 = 401,
  CODE_422 = 422,
  CODE_200 = 200,
}

export interface AdminLoginResponseInterface {
  success: boolean;
  user: UserInterface;
  token: string;
  statusCode?: number;
  errors?: ErrorInterface[] | string;
}

export interface AdminLoginRequestInterface {
  login: string;
  password: string;
}

export interface UserLoginResponseInterface {
  success: boolean;
  user: UserInterface;
  token: string;
  statusCode?: number;
  errors?: ErrorInterface[] | string;
}

export interface UserLoginRequestInterface {
  login: string;
  password: string;
}

export interface UserLogoutResponseInterface {
  success: boolean;
}
