import TableRow from "common/components/TableRow";
import { getDate, getTime } from "common/utils/time";
import { UserInterface } from "../../../../shared/interfaces/user";

enum UserTypeEnum {
  ADMIN = "ADMIN",
  USER = "USER",
}

type Props = {
  data: Partial<UserInterface>;
  onClick?: any;
};

const UsersTableRow: React.FC<Props> = ({ data: { name, email, type, lastActivity }, onClick }) => {
  const renderUserType = () => {
    switch (type) {
      case UserTypeEnum.ADMIN:
        return "Admin";
      case UserTypeEnum.USER:
        return "Użytkownik";
      default:
        return "Admin";
    }
  };
  return (
    <TableRow onClick={onClick}>
      <td>{name}</td>
      <td>{email}</td>
      <td>{renderUserType()}</td>
      {lastActivity ? (
        <td>
          {getDate(lastActivity)}
          <span className="ms-2"> {getTime(lastActivity)}</span>
        </td>
      ) : (
        <td>-</td>
      )}
    </TableRow>
  );
};

export default UsersTableRow;
