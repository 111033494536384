import { createApi } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "app/apiConfig";
import { BaseListResponseInterface } from "../../../../shared/interfaces/base";
import {
  MassageHistoryInterface,
  MassageHistoryFilteringDataInterface,
} from "../../../../shared/interfaces/massage-history";

export const reportApi = createApi({
  reducerPath: "reportApi",
  baseQuery: customBaseQuery,
  tagTypes: ["Report"],
  endpoints: (build) => ({
    getReports: build.query<BaseListResponseInterface<MassageHistoryInterface>, MassageHistoryFilteringDataInterface>({
      query: (query) => ({
        url: `massageHistory/massageHistory`,
        params: query,
      }),
      providesTags: ["Report"],
    }),
  }),
});

export const { useLazyGetReportsQuery } = reportApi;
