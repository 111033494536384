import { useState } from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";
import AppButton, { ButtonColorTypes } from "./AppButton";

type Props = {
  value?: string;
  onSelect: (value: string) => void;
  error?: string;
};

const AppImagePicker: React.FC<Props> = ({ value, onSelect, error }) => {
  const [images, setImages] = useState<ImageListType>([]);

  const onChange = (imageList: ImageListType) => {
    setImages(images);
    if (imageList && imageList[0] && imageList[0].data) onSelect(imageList[0].data);
  };

  return (
    <div className="col-md-12 d-flex gap-2 flex-column align-items-start">
      <ImageUploading
        value={images}
        onChange={onChange}
        maxNumber={1}
        dataURLKey="data"
        acceptType={["jpg", "png"]}
        maxFileSize={2000000}
        resolutionType="absolute"
        resolutionHeight={300}
        resolutionWidth={300}
      >
        {({ onImageUpload, errors }) => {
          return (
            <>
              <AppButton
                title={value ? "Zmień zdjęcie" : "Dodaj zdjęcie"}
                color={ButtonColorTypes.SECONDARY}
                onClick={onImageUpload}
              />
              {value && <img id="my-image" src={value} alt="selected" />}
              <p className="small text-muted">
                Format: JPG/PNG <br /> Wymiary: 300x300
                <br />
                Rozmiar: &lt;2MB
              </p>
              {error && <div className="invalid-tooltip">{error}</div>}
              {errors?.acceptType && <div className="invalid-tooltip">Zły format pliku</div>}
              {errors?.maxFileSize && <div className="invalid-tooltip">Zły rozmiar pliku</div>}
              {errors?.resolution && <div className="invalid-tooltip">Złe wymiary pliku</div>}
            </>
          );
        }}
      </ImageUploading>
    </div>
  );
};

export default AppImagePicker;
