import AppButton, { ButtonColorTypes } from "./AppButton";

type Props = {
  label: string;
  show: boolean;
  cancelLabel?: string;
  confirmLabel?: string;
  onCancel: () => void;
  onConfirm: () => void;
};

const AppConfirmModal: React.FC<Props> = ({ label, show, cancelLabel, confirmLabel, onCancel, onConfirm }) => {
  return (
    <div className={`modal fade ${show && "show"}`} style={{ display: show ? "flex" : "none" }}>
      <div className="modal-dialog modal-sm modal-dialog-centered w-100">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-center w-100">{label}</h5>
          </div>
          <div className="modal-footer d-flex justify-content-center flex-nowrap px-3">
            <AppButton
              customClass="col-6"
              title={cancelLabel || "Nie"}
              color={ButtonColorTypes.SECONDARY}
              onClick={onCancel}
            />
            <AppButton
              customClass="col-6"
              title={confirmLabel || "Tak"}
              color={ButtonColorTypes.PRIMARY}
              onClick={onConfirm}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppConfirmModal;
