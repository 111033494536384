import Empty from "../../assets/images/empty.svg";
import _ from "lodash";
import AppLoader from "./AppLoader";

type Props = {
  headers: string[];
  rows?: React.ReactNode[] | null;
  noDataMsg?: string;
  isLoading?: boolean;
};

const Table: React.FC<Props> = ({ headers, rows, noDataMsg, isLoading }) => {
  const renderHeaders = () => {
    return headers.map((header: string) => {
      return (
        <th scope="col" key={header}>
          {header}
        </th>
      );
    });
  };

  const renderRows = () => {
    if (isLoading) {
      return (
        <tr>
          <td>
            <AppLoader show={isLoading} />
          </td>
        </tr>
      );
    }

    if (!rows || _.isEmpty(rows)) {
      return (
        <tr>
          <td colSpan={headers.length} className="border-0 text-center ">
            <div className="empty-list d-flex justify-content-center flex-column align-items-center">
              <img src={Empty} alt="empty" className="mt-3" />
              <p className="font-weight-light">{noDataMsg || "-"}</p>
            </div>
          </td>
        </tr>
      );
    }

    return rows.map((row: React.ReactNode) => {
      return row;
    });
  };
  return (
    <div className="table-responsive border-top">
      <table className="table table-hover">
        <thead>
          <tr>{renderHeaders()}</tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
    </div>
  );
};

export default Table;
