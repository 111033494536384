import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import _ from "lodash";
import { AppRoutesEnum } from "common/types";
import { UserFilteringDataInterface } from "../../../../shared/interfaces/user";
import ArrowPrevIcon from "../../assets/images/prev.svg";
import ArrowNextIcon from "../../assets/images/next.svg";

type Props = {
  total: number;
  filteringData: UserFilteringDataInterface;
  onChange: React.Dispatch<React.SetStateAction<UserFilteringDataInterface>>;
  limit?: number;
};

const Pagination: React.FC<Props> = ({ filteringData, total, limit = 20, onChange }) => {
  let { page } = useParams();
  const [offset, setOffset] = useState<number>(0);

  const currentPage = page ? parseInt(page) : 0;
  const currentOffset = currentPage * limit - limit;
  const totalPages = Math.round(total / limit);

  useEffect(() => {
    if (page && currentOffset !== offset) {
      setOffset(currentOffset);

      const filterDataTmp = { ...filteringData };
      filterDataTmp.offset = offset;
      filterDataTmp.limit = limit;

      onChange(filterDataTmp);
    }
  }, [filteringData, page, currentOffset, offset, limit, onChange]);

  const generateLeftPages = () => {
    let pages = _.range(1, 6);

    if (totalPages < 8) {
      pages = _.range(1, 8);
    }

    pages = pages.filter((page: number) => page <= totalPages);

    if (currentPage > 4 && totalPages >= 8) {
      pages.splice(1, 4);
    }

    return (
      <>
        {renderPaginationPages(pages)}
        {totalPages >= 8 && <li className="page-item mx-3">...</li>}
      </>
    );
  };

  const generateCenterPages = () => {
    if (totalPages <= 8 || currentPage < 5 || (currentPage > totalPages - 4 && currentPage > 5)) {
      return;
    }

    const pages = [currentPage - 1, currentPage, currentPage + 1];

    return (
      <>
        {renderPaginationPages(pages)}
        <li className="page-item mx-3">...</li>
      </>
    );
  };

  const generateRightPages = () => {
    if (totalPages < 8) {
      return;
    }

    let pages = _.range(totalPages - 4, totalPages + 1);
    pages = pages.filter((page: number) => page <= totalPages);

    if (currentPage < totalPages - 3) {
      pages.splice(0, 4);
    }

    return renderPaginationPages(pages);
  };

  const renderPaginationPages = (pages: number[]) => {
    return (
      <>
        {pages.map((paginationPage: number, index: number) => {
          return (
            <li
              key={`pagination-page-${index}`}
              className={`page-item ${currentPage === paginationPage ? "active" : ""}`}
            >
              <Link className="page-link" to={`${AppRoutesEnum.Users}/${paginationPage}`}>
                {paginationPage}
              </Link>
            </li>
          );
        })}
      </>
    );
  };

  const leftArrow = () => {
    if (!totalPages || (total && currentPage === 1)) {
      return;
    }

    return (
      <li className="page-item">
        <Link className="page-link" to={`${AppRoutesEnum.Users}/${currentPage - 1}`}>
          <img src={ArrowPrevIcon} alt="prev arrow" />
        </Link>
      </li>
    );
  };

  const rightArrow = () => {
    if (!totalPages || (total && currentPage >= totalPages)) {
      return;
    }

    return (
      <li className="page-item">
        <Link className="page-link" to={`${AppRoutesEnum.Users}/${currentPage + 1}`}>
          <img src={ArrowNextIcon} alt="next arrow" />
        </Link>
      </li>
    );
  };

  return (
    <nav>
      <ul className="pagination d-flex justify-content-center align-items-center">
        {leftArrow()}
        {generateLeftPages()}
        {generateCenterPages()}
        {generateRightPages()}
        {rightArrow()}
      </ul>
    </nav>
  );
};

export default Pagination;
