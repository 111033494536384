import _ from "lodash";
import ModuleContentContainer from "common/components/ModuleContentContainer";
import ModuleHeader from "common/components/ModuleHeader";
import PageWrapper from "common/components/PageWrapper";
import Table from "common/components/Table";
import { useEffect, useState } from "react";
import { MassageHistoryFilteringDataInterface } from "../../../../shared/interfaces/massage-history";
import ReportTableRow, { REPORTS_HEADERS } from "./ReportsTableRow";
import { useLazyGetReportsQuery } from "./reportApi";
import ReportFilters from "./ReportFilters";
import Pagination from "common/components/Pagination";

const Reports: React.FC = () => {
  const [filteringData, setFilteringData] = useState<MassageHistoryFilteringDataInterface>({});
  const [getReports, { data: reports, isLoading }] = useLazyGetReportsQuery();

  useEffect(() => {
    getReports(filteringData);
  }, [getReports, filteringData]);

  const renderRows = () => {
    if (!reports) return null;
    return reports.documents.map((d) => {
      return <ReportTableRow data={d} key={d._id} />;
    });
  };

  return (
    <PageWrapper>
      <ModuleHeader title="Raporty" />
      <ModuleContentContainer>
        <ReportFilters filteringData={filteringData} onChange={setFilteringData} />
        <Table headers={REPORTS_HEADERS} noDataMsg="Brak zdarzeń" rows={renderRows()} isLoading={isLoading} />
        <Pagination filteringData={filteringData} onChange={setFilteringData} total={_.get(reports, "total", 1)} />
      </ModuleContentContainer>
    </PageWrapper>
  );
};

export default Reports;
