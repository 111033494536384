type Props = {
  children: React.ReactNode;
};

const ModuleContentContainer: React.FC<Props> = ({ children }) => {
  return <div className="container-fluid">
    <div className="row">
      <div className="col-12">
        {children}
      </div>
    </div>
  </div>;
};

export default ModuleContentContainer;
