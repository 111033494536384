import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import Login from "features/login/Login";
import App from "features/app/App";
import Reports from "features/report/Reports";
import Users from "features/user/Users";
import AddAdmin from "features/user/AddAdmin";
import Massages from "features/massage/Massages";
import Massage from "features/massage/Massage";
import { AppRoutesEnum } from "common/types";
import UserDetails from "features/user/UserDetails";
import Issues from "features/issue/Issues";

function Root() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={AppRoutesEnum.Login} element={<Login />} />
        <Route path="/" element={<App />}>
          <Route path={AppRoutesEnum.Reports} element={<Reports />} />
          <Route path={AppRoutesEnum.Users} element={<Users />} />
          <Route path={`${AppRoutesEnum.Users}/:page`} element={<Users />} />
          <Route path={`${AppRoutesEnum.UserDetails}:id`} element={<UserDetails />} />
          <Route path={AppRoutesEnum.AddAdmin} element={<AddAdmin />} />
          <Route path={AppRoutesEnum.Massages} element={<Massages />} />
          <Route path={`${AppRoutesEnum.EditMassage}:id`} element={<Massage />} />
          <Route path={AppRoutesEnum.AddMassage} element={<Massage />} />
          <Route path={AppRoutesEnum.Issues} element={<Issues />} />
          <Route path="*" element={<Navigate to={AppRoutesEnum.Reports} replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Root;
