import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { loginApi } from "features/login/loginApi";
import { massageApi } from "features/massage/massageApi";
import { userApi } from "features/user/userApi";
import { reportApi } from "features/report/reportApi";
import { issueApi } from "features/issue/issueApi";

export const store = configureStore({
  reducer: {
    [loginApi.reducerPath]: loginApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [massageApi.reducerPath]: massageApi.reducer,
    [reportApi.reducerPath]: reportApi.reducer,
    [issueApi.reducerPath]: issueApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(loginApi.middleware)
      .concat(userApi.middleware)
      .concat(massageApi.middleware)
      .concat(reportApi.middleware)
      .concat(issueApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
