import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

export const handleDocumentResponseError = (
  error: FetchBaseQueryError | SerializedError | undefined,
  callback: (value: React.SetStateAction<null>) => void,
) => {
  if (error && "data" in error) {
    callback((error as any).data.errors); // todo we could investigate why TS complains about unknown type and get rid of any
  }
};
