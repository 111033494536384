type Props = {
  value: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  error?: string;
  placeholder?: string;
  innerLabel?: string;
  rows?: number;
};

const AppTextarea: React.FC<Props> = ({ value, onChange, error, placeholder, innerLabel, rows }) => {
  return (
    <div className={`col-md-12 position-relative ${innerLabel && "input-group"}`}>
      <textarea
        className="form-control error-form"
        rows={rows}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      ></textarea>
      {innerLabel && <span className="input-group-text">{innerLabel}</span>}
      {error && <div className="invalid-tooltip">{error}</div>}
    </div>
  );
};

export default AppTextarea;
