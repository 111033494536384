import { useEffect, useState } from "react";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AppButton, { ButtonColorTypes } from "common/components/AppButton";
import AppInput, { InputTypes } from "common/components/AppInput";
import AppTextarea from "common/components/AppTextarea";
import LabeledContainer from "common/components/LabeledContainer";
import ModuleContentContainer from "common/components/ModuleContentContainer";
import ModuleHeader from "common/components/ModuleHeader";
import PageWrapper from "common/components/PageWrapper";
import AppSelect, { OptionType } from "common/components/AppSelect";
import {
  EngineEnum,
  EngineSettingInterface,
  MassageGroupEnum,
  MassageInterface,
  MassageSettingInterface,
} from "../../../../shared/interfaces/massage";
import MotorSettings from "./MotorSettings";
import { AllowedEngineCycleInputsEnum, AppRoutesEnum } from "common/types";
import AppImagePicker from "common/components/AppImagePicker";
import {
  useCreateMassageMutation,
  useUpdateMassageMutation,
  useLazyGetMassageQuery,
  useDeleteMassageMutation,
} from "./massageApi";
import { handleDocumentResponseError } from "common/utils/error";
import AppConfirmModal from "common/components/AppConfirmModal";
import AppCheckbox from "common/components/AppCheckbox";
import { MOTORS } from "common/consts";

const GroupOptions: OptionType[] = [
  { label: "Energetyczne", value: MassageGroupEnum.GROUP_1 },
  { label: "Zdrowotne", value: MassageGroupEnum.GROUP_2 },
  { label: "Relaksasyjne", value: MassageGroupEnum.GROUP_3 },
  { label: "Regenerujące", value: MassageGroupEnum.GROUP_4 },
];

const EmptyCicle: EngineSettingInterface = {
  time: 0,
  power: 0,
  smoothTransition: 0,
};

const Massage: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [icon, setIcon] = useState<string>("");
  const [massageTime, setMassageTime] = useState<number>(0);
  const [massageGroup, setMassageGroup] = useState<MassageGroupEnum | undefined>();
  const [massageSettings, setMassageSettings] = useState<MassageSettingInterface[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [motorsToSelect, setMotorsToSelect] = useState<OptionType[]>(JSON.parse(JSON.stringify(MOTORS)));
  const [isLooped, setIsLooped] = useState<boolean>(false);
  const [loopedNumber, setLoopedNumber] = useState<number>(0);

  const [errors, setErrors] = useState(null);

  const navigate = useNavigate();
  const { id } = useParams();

  const [getMassage, { data: getMassageData, error: getMassageError }] = useLazyGetMassageQuery();
  const [deleteMassage] = useDeleteMassageMutation();

  useEffect(() => {
    //ensure all motors are not disabled by default
    setDisabledMotors([]);
    //add first motor with empty config to avoid empty view
    addMotor();
  }, []);

  useEffect(() => {
    if (id) {
      getMassage(id);
    }
  }, [id, getMassage]);

  const handleSetApiData = (massage: MassageInterface) => {
    setName(massage.name);
    setDescription(massage.description);
    setIcon(massage.icon);
    setMassageTime(massage.massageTime);
    setMassageGroup(massage.massageGroup);
    setMassageSettings(massage.massageSettings);
    setActive(massage.active);
    setIsLooped(massage.isLooped || false);
    setLoopedNumber(massage.loopedNumber || 0);
    //disable motors that are used in loaded massage settings
    setDisabledMotors(massage.massageSettings);
  };

  const setDisabledMotors = (massageSettings: MassageSettingInterface[]) => {
    setMotorsToSelect(
      JSON.parse(JSON.stringify(MOTORS)).map((motor: OptionType) => {
        const selectedIndex = massageSettings.findIndex((m) => m.engineNumber === motor.value);
        if (selectedIndex > -1) motor.isDisabled = true;
        else motor.isDisabled = false;
        return motor;
      }),
    );
  };

  useEffect(() => {
    if (getMassageData?.success) {
      handleSetApiData(getMassageData.document);
    }
  }, [getMassageData]);

  useEffect(() => {
    if (getMassageError) {
      toast.error("Nie udało się pobrać masazu");
    }
  }, [getMassageError]);

  const [createMassage, { data: createMassageData, error: createMassageError }] = useCreateMassageMutation();

  useEffect(() => {
    if (createMassageData?.success) {
      navigate(AppRoutesEnum.Massages);
    }
  }, [createMassageData, navigate]);

  useEffect(() => {
    if (createMassageError) {
      handleDocumentResponseError(createMassageError, setErrors);
    }
  }, [createMassageError]);

  const [updateMassage, { data: updateMassageData, error: updateMassageError }] = useUpdateMassageMutation();

  useEffect(() => {
    if (updateMassageData?.success) {
      navigate(AppRoutesEnum.Massages);
    }
  }, [updateMassageData, navigate]);

  useEffect(() => {
    if (!isLooped) setLoopedNumber(0);
  }, [isLooped]);

  useEffect(() => {
    if (updateMassageError) {
      handleDocumentResponseError(updateMassageError, setErrors);
    }
  }, [updateMassageError]);

  const setMotor = (motorIndex: number, newVal: EngineEnum, oldVal: EngineEnum) => {
    const settings = massageSettings.map((setting: MassageSettingInterface, i: number) => {
      if (motorIndex === i) {
        return { ...setting, engineNumber: newVal };
      }
      return setting;
    });

    setMassageSettings(settings);
    handleMotorsToSelect(newVal, oldVal);
  };

  const handleMotorsToSelect = (newVal: EngineEnum, oldVal: EngineEnum) => {
    const newOptionIndex = _.findIndex(motorsToSelect, { value: newVal });
    const oldOptionIndex = _.findIndex(motorsToSelect, { value: oldVal });

    let tmpMotorsToSelect = [...motorsToSelect];

    if (newVal !== oldVal) {
      if (tmpMotorsToSelect[newOptionIndex]) tmpMotorsToSelect[newOptionIndex].isDisabled = true;
      if (tmpMotorsToSelect[oldOptionIndex]) tmpMotorsToSelect[oldOptionIndex].isDisabled = false;
    } else if (tmpMotorsToSelect[newOptionIndex]) tmpMotorsToSelect[newOptionIndex].isDisabled = true;

    setMotorsToSelect(tmpMotorsToSelect);
  };

  const setCycleInput = (
    motorIndex: number,
    cycleIndex: number,
    value: number,
    field: AllowedEngineCycleInputsEnum,
  ) => {
    const settings = massageSettings.map((setting: MassageSettingInterface, i: number) => {
      if (motorIndex === i) {
        return {
          ...setting,
          engineSettings: setting.engineSettings.map((cycle: EngineSettingInterface, j: number) => {
            if (cycleIndex === j) {
              return { ...cycle, [field]: value };
            }
            return cycle;
          }),
        };
      }
      return setting;
    });

    setMassageSettings(settings);
  };

  const addCycle = (motorIndex: number) => {
    const settings = massageSettings.map((setting: MassageSettingInterface, i: number) => {
      if (motorIndex === i) {
        return {
          ...setting,
          engineSettings: [...setting.engineSettings, EmptyCicle],
        };
      }
      return setting;
    });
    setMassageSettings(settings);
  };

  const addMotor = () => {
    //find motor that is not selected
    const availableMotor = motorsToSelect.find((motor) => {
      //if true motor is already selected
      const isSelected = massageSettings.find((m) => m.engineNumber === motor.value);
      return !isSelected;
    });

    if (availableMotor) {
      //if any motor is availible add it to settings with empty config
      setMassageSettings([
        ...massageSettings,
        { engineNumber: availableMotor.value as EngineEnum, engineSettings: [EmptyCicle] },
      ]);
      //disable motor that we added
      const newMotorsToSelect = motorsToSelect.map((motor) => {
        if (motor.value === availableMotor.value) motor.isDisabled = true;
        return motor;
      });
      setMotorsToSelect(newMotorsToSelect);
    }
  };

  const removeCycle = (motorIndex: number, cycleIndex: number) => {
    if (motorIndex === 0 && cycleIndex === 0) return;
    if (cycleIndex === 0 && massageSettings[motorIndex].engineSettings.length === 1) {
      removeMotor(motorIndex);
      return;
    }
    const settings = massageSettings.map((setting: MassageSettingInterface, i: number) => {
      if (motorIndex === i) {
        return {
          ...setting,
          engineSettings: setting.engineSettings.filter((el: EngineSettingInterface, i: number) => i !== cycleIndex),
        };
      }
      return setting;
    });
    setMassageSettings(settings);
  };

  const removeMotor = (motorIndex: number) => {
    setMassageSettings(massageSettings.filter((el: MassageSettingInterface, i: number) => i !== motorIndex));
  };

  const renderMotors = () => {
    return massageSettings.map((setting: MassageSettingInterface, i: number) => {
      return (
        <MotorSettings
          key={i}
          setting={setting}
          motorIndex={i}
          setMotor={setMotor}
          setCycleInput={setCycleInput}
          addCycle={addCycle}
          removeCycle={removeCycle}
          motorsToSelect={motorsToSelect}
          error={_.get(errors, `massageSettings[${i}]`)}
        />
      );
    });
  };

  const handleSubmitForm = () => {
    if (id) {
      updateMassage({
        _id: id,
        name,
        description,
        icon,
        massageTime: calculateTimeTotal(),
        massageGroup,
        massageSettings,
        active,
        isLooped,
        loopedNumber,
      });
    } else {
      createMassage({
        name,
        description,
        icon,
        massageTime: calculateTimeTotal(),
        massageGroup,
        massageSettings,
        active,
        isLooped,
        loopedNumber,
      });
    }
  };

  const handleRemoveMassage = async () => {
    if (id) {
      const res = await deleteMassage({ id }).unwrap();

      if (res.success) {
        toast.success("Usunięto masaż.");
        navigate(AppRoutesEnum.Massages);
      } else {
        toast.success("Wystąpił błąd.");
      }
    }
  };

  const calculateTimeTotal = () => {
    const times: number[] = [];
    const multiplier = isLooped && loopedNumber > 0 ? loopedNumber + 1 : 1;
    massageSettings.forEach((massageSetting: MassageSettingInterface) => {
      let engineMassageTime = 0;
      if (massageSetting?.engineSettings?.length) {
        massageSetting.engineSettings.forEach((engineSetting: EngineSettingInterface) => {
          engineMassageTime += engineSetting.time + engineSetting.smoothTransition;
        });
      }
      times.push(engineMassageTime * multiplier);
    });
    return _.max(times) || 0;
  };

  return (
    <PageWrapper>
      <AppConfirmModal
        label="Usunąć masaż?"
        show={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        onConfirm={() => {
          setShowConfirmModal(false);
          handleRemoveMassage();
        }}
      />
      <ModuleHeader
        backButton={true}
        title="Nowy program"
        extraButtons={
          <>
            <AppButton title="Zapisz" color={ButtonColorTypes.PRIMARY} onClick={handleSubmitForm} />
            {id && (
              <AppButton
                customClass="ms-2"
                title="Usuń"
                color={ButtonColorTypes.SECONDARY}
                onClick={() => setShowConfirmModal(true)}
              />
            )}
          </>
        }
      />
      <ModuleContentContainer>
        <form id="massage-add" className="form-horizontal form-material mx-2 needs-validation">
          <div className="row">
            <div className="col-12 col-lg-8 row">
              <div className="col-12 col-md-6 col-lg-6">
                <LabeledContainer required={true} title="Nazwa">
                  <AppInput inputType={InputTypes.TEXT} value={name} onChange={setName} error={_.get(errors, "name")} />
                </LabeledContainer>
                <LabeledContainer required={true} title="Opis">
                  <AppTextarea
                    value={description}
                    onChange={setDescription}
                    error={_.get(errors, "description")}
                    rows={4}
                  />
                </LabeledContainer>
                <LabeledContainer required={true} title="Całkowity czas trwania">
                  <AppInput
                    inputType={InputTypes.NUMBER}
                    value={calculateTimeTotal().toString()}
                    onChange={(value: string) => setMassageTime(parseInt(value))}
                    error={_.get(errors, "massageTime")}
                    innerLabel="sekund"
                    disabled
                  />
                </LabeledContainer>
                <LabeledContainer required={true} title="Grupa">
                  <AppSelect
                    options={GroupOptions}
                    error={_.get(errors, "massageGroup")}
                    onSelect={setMassageGroup}
                    value={massageGroup}
                  />
                </LabeledContainer>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <LabeledContainer required={true} title="Grafika">
                  <AppImagePicker
                    value={icon}
                    onSelect={(value: string) => setIcon(value)}
                    error={_.get(errors, "icon")}
                  />
                </LabeledContainer>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <LabeledContainer required={true} title="Aktywność">
                  <AppCheckbox
                    label="Aktywuj dla użytkowników"
                    value={active}
                    onChange={setActive}
                    error={_.get(errors, "active")}
                  />
                </LabeledContainer>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <LabeledContainer required={false} title="Zapętl masaż">
                  <AppCheckbox
                    label="Zapętl masaż"
                    value={isLooped}
                    onChange={setIsLooped}
                    error={_.get(errors, "isLooped")}
                  />
                </LabeledContainer>
                {isLooped && (
                  <LabeledContainer required={true} title="Ilość powtórzeń">
                    <AppInput
                      inputType={InputTypes.NUMBER}
                      value={loopedNumber.toString()}
                      onChange={(value: string) => setLoopedNumber(parseInt(value))}
                      error={_.get(errors, "loopedNumber")}
                      innerLabel="razy"
                      disabled={!isLooped}
                    />
                  </LabeledContainer>
                )}
              </div>
            </div>

            <div className="col-12 row m-0 justify-content-end">
              {renderMotors()}
              <hr />
            </div>
          </div>
          <AppButton title="Dodaj silnik" color={ButtonColorTypes.SECONDARY} onClick={() => addMotor()} />
        </form>
      </ModuleContentContainer>
    </PageWrapper>
  );
};

export default Massage;
