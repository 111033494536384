type Props = {
  show: boolean | undefined;
};

const AppLoader: React.FC<Props> = ({ show }) => {
  return (
    <>
      {show && (
        <div className="lds-ripple">
          <div className="lds-pos"></div>
          <div className="lds-pos"></div>
        </div>
      )}
    </>
  );
};

export default AppLoader;
