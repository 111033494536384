import { useEffect, useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import AppButton, { ButtonColorTypes } from "common/components/AppButton";
import AppInput, { InputTypes } from "common/components/AppInput";
import LabeledContainer from "common/components/LabeledContainer";
import ModuleContentContainer from "common/components/ModuleContentContainer";
import ModuleHeader from "common/components/ModuleHeader";
import PageWrapper from "common/components/PageWrapper";
import { useCreateAdminMutation } from "./userApi";
import { handleDocumentResponseError } from "common/utils/error";
import { AppRoutesEnum } from "common/types";

const AddAdmin: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [errors, setErrors] = useState(null);

  const navigate = useNavigate();

  const [createAdmin, { data, error }] = useCreateAdminMutation();

  const handleSubmitForm = () => {
    createAdmin({
      name: name,
      email: email,
      password: password,
      passwordConfirm: passwordConfirm,
      active: true,
    });
  };

  useEffect(() => {
    if (data?.success) {
      navigate(AppRoutesEnum.Users);
    }
  }, [data, navigate]);

  useEffect(() => {
    if (error) {
      handleDocumentResponseError(error, setErrors);
    }
  }, [error]);

  return (
    <PageWrapper>
      <ModuleHeader
        backButton={true}
        title="Nowy administrator"
        extraButtons={<AppButton title="Zapisz" color={ButtonColorTypes.PRIMARY} onClick={handleSubmitForm} />}
      />
      <ModuleContentContainer>
        <form id="admin-add" className="form-horizontal form-material mx-2 needs-validation">
          <div className="row">
            <div className="col-12 col-lg-8 row">
              <div className="col-12 col-md-6 col-lg-5">
                <LabeledContainer required={true} title="Imię i nazwisko">
                  <AppInput inputType={InputTypes.TEXT} value={name} onChange={setName} error={_.get(errors, "name")} />
                </LabeledContainer>
              </div>
              <div className="col-12 col-md-12 col-lg-5">
                <LabeledContainer required={true} title="Email">
                  <AppInput
                    inputType={InputTypes.EMAIL}
                    value={email}
                    onChange={setEmail}
                    error={_.get(errors, "email")}
                  />
                </LabeledContainer>
              </div>
              <div className="col-12 col-md-6 col-lg-5">
                <LabeledContainer required={true} title="Hasło">
                  <AppInput
                    inputType={InputTypes.PASSWORD}
                    value={password}
                    onChange={setPassword}
                    error={_.get(errors, "password")}
                  />
                </LabeledContainer>
              </div>
              <div className="col-12 col-md-6 col-lg-5">
                <LabeledContainer required={true} title="Hasło">
                  <AppInput
                    inputType={InputTypes.PASSWORD}
                    value={passwordConfirm}
                    onChange={setPasswordConfirm}
                    error={_.get(errors, "passwordConfirm")}
                  />
                </LabeledContainer>
              </div>
            </div>
          </div>
        </form>
      </ModuleContentContainer>
    </PageWrapper>
  );
};

export default AddAdmin;
