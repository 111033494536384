import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import AppButton, { ButtonColorTypes } from "common/components/AppButton";
import ModuleContentContainer from "common/components/ModuleContentContainer";
import ModuleHeader from "common/components/ModuleHeader";
import PageWrapper from "common/components/PageWrapper";
import Table from "common/components/Table";
import { AppRoutesEnum } from "common/types";
import { MassageFilteringDataInterface } from "../../../../shared/interfaces/massage";
import { useLazyGetMassagesQuery } from "./massageApi";
import MassagesTableRow from "./MassagesTableRow";
import MassageFilters from "./MassageFilters";
import Pagination from "common/components/Pagination";

const MASSAGES_HEADERS = ["Status", "", "Nazwa", "Grupa", "Czas trwania", "Opis"];

const Massages: React.FC = () => {
  const [filteringData, setFilteringData] = useState<MassageFilteringDataInterface>({});
  const [getMassages, { data: massages, isLoading }] = useLazyGetMassagesQuery();
  const navigate = useNavigate();

  useEffect(() => {
    getMassages(filteringData);
  }, [getMassages, filteringData]);

  const renderRows = () => {
    if (!massages) return null;
    return massages.documents.map((d) => {
      return <MassagesTableRow data={d} key={d._id} />;
    });
  };

  const handleNavigateToAddMassage = () => {
    navigate(AppRoutesEnum.AddMassage);
  };

  return (
    <PageWrapper>
      <ModuleHeader
        title="Lista programów masujących"
        extraButtons={
          <>
            <AppButton title="Dodaj nowy" color={ButtonColorTypes.PRIMARY} onClick={handleNavigateToAddMassage} />
          </>
        }
      />
      <ModuleContentContainer>
        <MassageFilters filteringData={filteringData} onChange={setFilteringData} />
        <Table headers={MASSAGES_HEADERS} noDataMsg="Brak masaży" rows={renderRows()} isLoading={isLoading} />
        <Pagination filteringData={filteringData} onChange={setFilteringData} total={_.get(massages, "total", 1)} />
      </ModuleContentContainer>
    </PageWrapper>
  );
};

export default Massages;
