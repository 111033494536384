import Footer from "common/components/Footer";

type Props = {
  children: React.ReactNode;
};

const PageWrapper: React.FC<Props> = ({ children }) => {
  return (
    <div className="page-wrapper">
      {children}
      <Footer />
    </div>
  );
};

export default PageWrapper;
