import { BaseInterface } from "./base";

export interface UserInterface extends BaseInterface {
  _id: string;
  createdAt: string;
  name?: string;
  email?: string;
  password: string;
  regulationAccepted: boolean;
  privacyAccepted: boolean;
  type: UserTypeEnum;
  sex?: SexEnum;
  weight?: number;
  height?: number;
  address?: UserAddressInterface;
  trainingHours?: UserActivityInterface[];
  workingHours?: UserActivityInterface[];
  sleepingHours?: UserActivityInterface[];
  homeHours?: UserActivityInterface[];
  devicesSerialNumber: string[];
  tokens?: string[];
  lastActivity?: Date;
  fbId?: string;
  googleId?: string;
  appleId?: string;
}

export interface UserActivityInterface {
  dayName: DayNameEnum;
  hourFrom?: number;
  hourTo?: number;
}

export interface UserAddressInterface {
  formattedAddress?: string;
  coordinates?: number[];
}

export enum DayNameEnum {
  MON = "MON",
  TUE = "TUE",
  WED = "WED",
  THU = "THU",
  FRI = "FRI",
  SAT = "SAT",
  SUN = "SUN",
}

export enum SexEnum {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export enum UserTypeEnum {
  ADMIN = "ADMIN",
  USER = "USER",
}

export interface CreateUserRequestInterface {
  name: string;
  email: string;
  password: string;
  passwordConfirm: string;
  active: true;
}

export interface UpdateUserRequestInterface
  extends Partial<CreateUserRequestInterface> {
  _id: string;
  devicesSerialNumber: string[];
}

export interface UpdateMobileUserRequestInterface
  extends Partial<UserInterface> {
  _id: string;
}

export interface DeleteUserRequestInterface {
  id: string;
}

export interface RegisterUserRequestInterface {
  name: string;
  email: string;
  password: string;
  passwordConfirm: string;
  agreements: boolean;
}

export interface FacebookLoginOrRegisterUserRequestInterface {
  fbId: string;
  name?: string;
  email?: string;
  agreements?: boolean;
}

export interface GoogleLoginOrRegisterUserRquestInterface {
  googleId: string;
  name?: string | null;
  email?: string;
  agreements?: boolean;
}

export interface AppleLoginOrRegisterUserRquestInterface {
  appleId: string;
  name?: string | null;
  email?: string | null;
  agreements?: boolean;
}

export interface UserFilteringDataInterface {
  name?: string;
  type?: string;
  offset?: number;
  limit?: number;
}

export enum UserFilterTypesEnum {
  NAME = "name",
  TYPE = "type",
}

export interface UserFavoriteMassageStatistic {
  name: string;
  info: string;
}

export interface UserDeviceWorkTimeStatistic {
  time: string;
  info: string;
}

export interface UserFavoriteMassageHourStatistic {
  hours: string[];
  info: string;
}

export interface UserStatistics {
  favoriteMassage: UserFavoriteMassageStatistic;
  deviceWorkTime: UserDeviceWorkTimeStatistic;
  favoriteMassageHours: UserFavoriteMassageHourStatistic;
}

export interface StatisticsResponseInterface {
  success: boolean;
  document: UserStatistics;
}

export interface ForgotPasswordInterface {
  login: string;
}

export interface ResetPasswordInterface {
  code: string;
  password: string;
  passwordConfirm: string;
}
