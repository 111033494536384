import { useEffect, useState } from "react";
import FooterLogo from "../../assets/images/footer-logo.svg";
import FooterImg1 from "../../assets/images/foot-bg.png";
import FooterImg2 from "../../assets/images/foot-bg2.png";
import FooterImg3 from "../../assets/images/foot-bg3.png";
import FooterImg4 from "../../assets/images/foot-bg5.png";

const Footer = () => {
  const [rng, setRng] = useState<number>(0);

  useEffect(() => {
    setRng(Math.floor(Math.random() * (4 - 0) + 1));
  }, []);

  const getRandomImg = () => {
    switch (rng) {
      case 1:
        return FooterImg1;
      case 2:
        return FooterImg2;
      case 3:
        return FooterImg3;
      case 4:
        return FooterImg4;
      default:
        return FooterImg1;
    }
  };

  return (
    <footer className="footer text-right">
      {rng && <img src={getRandomImg()} alt="random-img" id="footerPicture" />}
      <img src={FooterLogo} alt="logo" />
    </footer>
  );
};

export default Footer;
