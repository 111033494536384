import { BaseInterface } from "./base";

export interface MassageInterface extends BaseInterface {
  _id?: string;
  name: string;
  icon: string;
  description: string;
  massageTime: number;
  massageGroup: MassageGroupEnum;
  massageSettings: MassageSettingInterface[];
  active: boolean;
  averageRating?: number;
  isLooped?: boolean;
  loopedNumber?: number;
}

export interface MassageSettingInterface {
  engineNumber: EngineEnum;
  engineSettings: EngineSettingInterface[];
}

export interface EngineSettingInterface {
  time: number;
  power: number;
  smoothTransition: number;
}

export enum EngineEnum {
  ONE_MOTOR = "oneMotor",
  TWO_MOTOR = "twoMotor",
  THREE_MOTOR = "threeMotor",
  FOUR_MOTOR = "fourMotor",
  FIVE_MOTOR = "fiveMotor",
  SIX_MOTOR = "sixMotor",
  SEVEN_MOTOR = "sevenMotor",
  EIGHT_MOTOR = "eightMotor",
  NINE_MOTOR = "nineMotor",
  TEN_MOTOR = "tenMotor",
  ELEVEN_MOTOR = "elevenMotor",
  TWELVE_MOTOR = "twelveMotor",
  THIRTEEN_MOTOR = "thirteenMotor",
  FOURTEEN_MOTOR = "fourteenMotor",
  FIFTEEN_MOTOR = "fifteenMotor",
  SIXTEEN_MOTOR = "sixteenMotor",
}

export enum MassageGroupEnum {
  GROUP_1 = "Energia",
  GROUP_2 = "Zdrowie",
  GROUP_3 = "Relaks",
  GROUP_4 = "Regeneracja",
}

export interface CreateMassageRequestInterface {
  name: string;
  icon: string;
  description: string;
  massageTime: number;
  massageGroup: string | undefined;
  massageSettings: MassageSettingInterface[];
  active: boolean;
  isLooped: boolean;
  loopedNumber: number;
}

export interface UpdateMassageRequestInterface
  extends CreateMassageRequestInterface {
  _id: string;
}

export interface DeleteMassageRequestInterface {
  id: string;
}

export interface MassageFilteringDataInterface {
  name?: string;
  massageGroup?: string;
  active?: string;
  offset?: number;
  limit?: number;
}

export enum MassageFilterTypesEnum {
  NAME = "name",
  MASSAGE_GROUP = "massageGroup",
  ACTIVE = "active",
}

export interface MassageInGroupCountInterface {
  _id: string;
  total: number;
}
