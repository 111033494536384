type Props = {
  value: boolean | undefined;
  onChange: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  error?: string;
};

const AppCheckbox: React.FC<Props> = ({ label, value, onChange, error }) => {
  return (
    <>
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
        />
        <label className="form-check-label">{label}</label>
      </div>
      {error && <div className="invalid-tooltip">{error}</div>}
    </>
  );
};

export default AppCheckbox;
