import AppButton, { ButtonColorTypes } from "common/components/AppButton";
import AppInput, { InputTypes } from "common/components/AppInput";
import AppSelect, { OptionType } from "common/components/AppSelect";
import LabeledContainer from "common/components/LabeledContainer";
import {
  MassageFilteringDataInterface,
  MassageFilterTypesEnum,
  MassageGroupEnum,
} from "../../../../shared/interfaces/massage";

const GroupOptions: OptionType[] = [
  { label: "Energetyczne", value: MassageGroupEnum.GROUP_1 },
  { label: "Zdrowotne", value: MassageGroupEnum.GROUP_2 },
  { label: "Relaksasyjne", value: MassageGroupEnum.GROUP_3 },
  { label: "Regenerujące", value: MassageGroupEnum.GROUP_4 },
];

const StatusOptions: OptionType[] = [
  { label: "Aktywna", value: "true" },
  { label: "Nieaktywna", value: "false" },
];

type Props = {
  filteringData: MassageFilteringDataInterface;
  onChange: React.Dispatch<React.SetStateAction<MassageFilteringDataInterface>>;
};

const MassageFilters: React.FC<Props> = ({ filteringData, onChange }) => {
  const handleChangingSingleField = (fieldName: MassageFilterTypesEnum, value: string): void => {
    const filterDataTmp = { ...filteringData };
    filterDataTmp[fieldName] = value;
    onChange(filterDataTmp);
  };

  const handleClearFilters = (): void => {
    onChange({});
  };

  return (
    <div className="pb-3" id="filtry">
      <div className="col-12 col-md-4 col-lg-3 d-flex w-100 gap-2">
        <div className="form-group col-12 col-lg-2">
          <LabeledContainer title="Nazwa programu">
            <AppInput
              inputType={InputTypes.TEXT}
              value={filteringData.name}
              onChange={(text: string) => {
                handleChangingSingleField(MassageFilterTypesEnum.NAME, text);
              }}
            />
          </LabeledContainer>
        </div>
        <div className="form-group col-12 col-lg-3">
          <LabeledContainer title="Grupa">
            <AppSelect
              value={filteringData.massageGroup}
              options={GroupOptions}
              onSelect={(value: MassageGroupEnum) =>
                handleChangingSingleField(MassageFilterTypesEnum.MASSAGE_GROUP, value)
              }
            />
          </LabeledContainer>
        </div>
        <div className="form-group col-12 col-lg-3">
          <LabeledContainer title="Status">
            <AppSelect
              value={filteringData.active}
              options={StatusOptions}
              onSelect={(value: string) => handleChangingSingleField(MassageFilterTypesEnum.ACTIVE, value)}
            />
          </LabeledContainer>
        </div>
        <div className="float-right mt-4 ms-auto">
          <AppButton title="Usuń filtry" onClick={handleClearFilters} color={ButtonColorTypes.LIGHT} />
        </div>
      </div>
    </div>
  );
};

export default MassageFilters;
