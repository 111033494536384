type Props = {
  title: string;
  children: React.ReactNode;
  required?: boolean;
};

const LabeledContainer: React.FC<Props> = ({ title, required, children }) => {
  return (
    <div className="form-group">
      <label className="col-md-12">
        {title}
        {required && <span className="primary">*</span>}
      </label>
      {children}
    </div>
  );
};

export default LabeledContainer;
