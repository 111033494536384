import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { toast } from "react-toastify";
import AppButton, { ButtonColorTypes } from "common/components/AppButton";
import AppInput, { InputTypes } from "common/components/AppInput";
import LabeledContainer from "common/components/LabeledContainer";
import ModuleContentContainer from "common/components/ModuleContentContainer";
import ModuleHeader from "common/components/ModuleHeader";
import PageWrapper from "common/components/PageWrapper";
import { AppRoutesEnum } from "common/types";
import { useDeleteUserMutation, useLazyGetUserQuery, useUpdateUserMutation } from "./userApi";
import { UserInterface } from "../../../../shared/interfaces/user";
import UserStatContainer, { UserStatIconEnum } from "./UserStatContainer";
import ReportTableRow, { REPORTS_HEADERS } from "features/report/ReportsTableRow";
import Table from "common/components/Table";
import UserTimeSheet from "./UserTimeSheet";
import UserSimpleData from "./UserSimpleData";
import { getDate } from "common/utils/time";
import AppConfirmModal from "common/components/AppConfirmModal";
import { useLazyGetReportsQuery } from "features/report/reportApi";
import Map from "./Map";

const UserDetails: React.FC = () => {
  const [user, setUser] = useState<UserInterface>();
  const [serialNumber, setSerialNumber] = useState<string>("");
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const { id } = useParams();
  const [getUser, { data: userData, error: getUserError }] = useLazyGetUserQuery();
  const [getReports, { data: reports, isLoading }] = useLazyGetReportsQuery();
  const [updateUser, { data: userUpdateData, error: userUpdateError }] = useUpdateUserMutation();
  const [deleteUser] = useDeleteUserMutation();

  useEffect(() => {
    if (id) {
      getUser(id);
      getReports({ "user.id": id });
    }
  }, [id, getUser, getReports]);

  useEffect(() => {
    if (userData?.success) {
      setUser(userData.document);
    }
  }, [userData]);

  useEffect(() => {
    if (getUserError) {
      console.error(getUserError);
      toast.error("Nie udało się pobrać uzytkownika");
    }
  }, [getUserError]);

  useEffect(() => {
    if (userUpdateData?.success) {
      setUser(userUpdateData.document);
    }
  }, [userUpdateData]);

  useEffect(() => {
    if (userUpdateError) {
      console.error(userUpdateError);
      toast.error("Nie udało się zaktualizować uzytkownika");
    }
  }, [userUpdateError]);

  const renderDevices = () => {
    return user?.devicesSerialNumber.map((device: string) => {
      return (
        <li key={device}>
          {device}
          <button
            className="btn btn-secondary btn-sm py-0 px-2 ms-2 mt--1"
            onClick={() => handleRemoveSerialNumber(device)}
          >
            x
          </button>
        </li>
      );
    });
  };

  const renderRows = () => {
    if (!reports) return null;
    return reports.documents.map((d) => {
      return <ReportTableRow data={d} key={d._id} />;
    });
  };

  const handleAddSerialNumber = () => {
    if (id && user) {
      updateUser({
        _id: id,
        devicesSerialNumber: [...user.devicesSerialNumber, serialNumber],
      });
      setSerialNumber("");
    }
  };

  const handleRemoveSerialNumber = (serialNumber: string) => {
    if (id && user) {
      updateUser({
        _id: id,
        devicesSerialNumber: _.without(user.devicesSerialNumber, serialNumber),
      });
      setSerialNumber("");
    }
  };

  const handleRemoveUser = async () => {
    if (id) {
      const res = await deleteUser({ id }).unwrap();

      if (res.success) {
        toast.success("Usunięto użytkownika.");
        navigate(AppRoutesEnum.Users);
      } else {
        toast.success("Wystąpił błąd.");
      }
    }
  };

  return (
    <PageWrapper>
      <AppConfirmModal
        label="Usunąć użytkownika?"
        show={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        onConfirm={() => {
          setShowConfirmModal(false);
          handleRemoveUser();
        }}
      />
      <ModuleHeader
        backButton={true}
        title={user?.name || ""}
        extraButtons={
          <AppButton title="Usuń" color={ButtonColorTypes.DESTRUCTIVE} onClick={() => setShowConfirmModal(true)} />
        }
      />
      <ModuleContentContainer>
        <div className="form-horizontal form-material mx-2">
          <div className="d-flex flex-wrap">
            <div className="col-12 col-lg-6 d-flex flex-wrap">
              <div className="col-12 col-md-6 col-lg-6">
                <LabeledContainer title="Email">
                  <UserSimpleData data={user?.email} />
                </LabeledContainer>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <LabeledContainer title="Płeć">
                  <UserSimpleData data={user?.sex} />
                </LabeledContainer>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <LabeledContainer title="Waga">
                  <UserSimpleData data={user?.weight} addString="kg" />
                </LabeledContainer>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <LabeledContainer title="Wzrost">
                  <UserSimpleData data={user?.height} addString="cm" />
                </LabeledContainer>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <LabeledContainer title="Adres zamieszkania">
                  <UserSimpleData data={user?.address?.formattedAddress} />
                </LabeledContainer>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <LabeledContainer title="Data rejestracji">
                  <UserSimpleData data={getDate(new Date(user?.createdAt as string))} />
                </LabeledContainer>
              </div>
              <div className="col-12 col-md-6 col-lg-6 pe-0 pe-md-3">
                <Map coordinates={_.get(user, "address.coordinates", [])} />
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <LabeledContainer title="Urządzenia">
                  <div className="col-sm-12">
                    <ul className="devices-list col-sm-12 mb-1">{renderDevices()}</ul>
                    <div className="row">
                      <div className="col-sm-8 d-flex">
                        <AppInput
                          placeholder="Podaj numer seryjny"
                          inputType={InputTypes.TEXT}
                          onChange={setSerialNumber}
                          value={serialNumber}
                        />
                        <div className="me-2" />
                        <AppButton title="+" color={ButtonColorTypes.PRIMARY} onClick={handleAddSerialNumber} />
                      </div>
                    </div>
                  </div>
                </LabeledContainer>
              </div>
            </div>

            <div className="col-12 col-lg-6 stat-tiles pe-lg-0">
              <UserStatContainer icon={UserStatIconEnum.Fav}>
                <div className="tile-text">
                  <h4 className="subheading">Ulubiony program</h4>
                  <h3 className="heading">Relaks po pracy</h3>
                  <p className="details">
                    72 uruchomienia <span className="worktime">(34h 45m)</span>
                  </p>
                </div>
              </UserStatContainer>
              <UserStatContainer icon={UserStatIconEnum.Play}>
                <div className="tile-text">
                  <h4 className="subheading">Czas pracy urządzeń</h4>
                  <h3 className="heading">52h 31min</h3>
                  <p className="details"></p>
                </div>
              </UserStatContainer>
              <UserStatContainer icon={UserStatIconEnum.Clock}>
                <div className="tile-text">
                  <h4 className="subheading">Ulubione godziny masażu po...</h4>
                  <h3 className="heading">
                    19:00<span>18:00</span>
                    <span>7:00</span>
                  </h3>
                  <p className="details">45% uruchomień w tej godzinie</p>
                </div>
              </UserStatContainer>
              <UserStatContainer icon={UserStatIconEnum.Steps}>
                <div className="tile-text">
                  <h4 className="subheading">Ilość kroków dzisiaj</h4>
                  <h3 className="heading">8762</h3>
                  <p className="details"></p>
                </div>
              </UserStatContainer>
            </div>
          </div>
          <div className="col-12">
            <h5 className="my-3">Aktywność</h5>
            <Table headers={REPORTS_HEADERS} noDataMsg="Brak zdarzeń" rows={renderRows()} isLoading={isLoading} />
          </div>
          <div className="row">
            <div className="col-12 row">
              <h5 className="my-3">Dodatkowe informacje</h5>
              <hr className="m-3 mt-0" />
              <UserTimeSheet title="Częstotliwość ćwiczeń" data={user?.trainingHours} />
              <UserTimeSheet title="Godziny pracy" data={user?.workingHours} />
              <UserTimeSheet title="Czas w domu" data={user?.homeHours} />
              <UserTimeSheet title="Godziny snu" data={user?.sleepingHours} />
            </div>
          </div>
        </div>
      </ModuleContentContainer>
    </PageWrapper>
  );
};

export default UserDetails;
