import { useNavigate } from "react-router-dom";
import BackNav from "../../assets/images/back-nav.svg";

type Props = {
  title: string;
  extraButtons?: React.ReactNode;
  backButton?: boolean;
};

const ModuleHeader: React.FC<Props> = ({ title, extraButtons, backButton }) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="page-breadcrumb">
      <div className="row align-items-center">
        <div className="col-12 col-md-8">
          {backButton && (
            <div className="m-icon float-start" style={{ cursor: "pointer" }} onClick={goBack}>
              <img src={BackNav} alt="back" />
            </div>
          )}
          <h1 className="mb-0 fw-bold">{title}</h1>
        </div>
        <div className="col-12 col-md-4">
          <div className="text-start text-lg-end upgrade-btn">{extraButtons}</div>
        </div>
      </div>
      {backButton && <hr />}
    </div>
  );
};

export default ModuleHeader;
