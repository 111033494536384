type Props = {
  data?: string | number;
  addString?: string;
};

const UserSimpleData: React.FC<Props> = ({ data, addString }) => {
  return (
    <div className="col-md-12">
      <span className="form-content">
        {data || "-"} {data && addString}
      </span>
    </div>
  );
};

export default UserSimpleData;
