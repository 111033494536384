import { createApi } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "app/apiConfig";
import { AdminLoginRequestInterface, AdminLoginResponseInterface } from "../../../../shared/interfaces/auth";

export const loginApi = createApi({
  reducerPath: "loginApi",
  baseQuery: customBaseQuery,
  tagTypes: ["Login"],
  endpoints: (build) => ({
    adminLogin: build.mutation<AdminLoginResponseInterface, AdminLoginRequestInterface>({
      query: (data) => ({
        url: `adminLogin`,
        method: "POST",
        body: data,
      }),
    }),
    checkAuth: build.query<AdminLoginResponseInterface, void>({
      query: () => ({
        url: `checkAuth`,
      }),
    }),
  }),
});

export const { useAdminLoginMutation, useCheckAuthQuery } = loginApi;
