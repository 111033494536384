import AppButton, { ButtonColorTypes } from "common/components/AppButton";
import AppInput, { InputTypes } from "common/components/AppInput";
import AppSelect, { OptionType } from "common/components/AppSelect";
import LabeledContainer from "common/components/LabeledContainer";
import { UserFilteringDataInterface, UserFilterTypesEnum, UserTypeEnum } from "../../../../shared/interfaces/user";

const UserRoleOptions: OptionType[] = [
  { label: "Administrator", value: UserTypeEnum.ADMIN },
  { label: "Użytkownik", value: UserTypeEnum.USER },
];

type Props = {
  filteringData: UserFilteringDataInterface;
  onChange: React.Dispatch<React.SetStateAction<UserFilteringDataInterface>>;
};

const UserFilters: React.FC<Props> = ({ filteringData, onChange }) => {
  const handleChangingSingleField = (fieldName: UserFilterTypesEnum, value: string): void => {
    const filterDataTmp = { ...filteringData };
    filterDataTmp[fieldName] = value;
    onChange(filterDataTmp);
  };

  const handleClearFilters = (): void => {
    onChange({});
  };

  return (
    <div className="pb-3" id="filtry">
      <div className="col-12 col-md-4 col-lg-3 d-flex w-100 gap-2">
        <div className="form-group col-12 col-lg-3">
          <LabeledContainer title="Użytkownik">
            <AppInput
              inputType={InputTypes.TEXT}
              value={filteringData.name}
              onChange={(text: string) => {
                handleChangingSingleField(UserFilterTypesEnum.NAME, text);
              }}
            />
          </LabeledContainer>
        </div>
        <div className="form-group col-12 col-lg-3">
          <LabeledContainer title="Rola">
            <AppSelect
              value={filteringData.type}
              options={UserRoleOptions}
              onSelect={(value: UserTypeEnum) => handleChangingSingleField(UserFilterTypesEnum.TYPE, value)}
            />
          </LabeledContainer>
        </div>
        <div className="float-right mt-4 ms-auto">
          <AppButton title="Usuń filtry" onClick={handleClearFilters} color={ButtonColorTypes.LIGHT} />
        </div>
      </div>
    </div>
  );
};

export default UserFilters;
