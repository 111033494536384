import AppStars from "common/components/AppStars";
import TableRow from "common/components/TableRow";
import { getDate, getDuration, getTime } from "common/utils/time";
import { MassageHistoryInterface } from "../../../../shared/interfaces/massage-history";

export const REPORTS_HEADERS = ["Imię i nazwisko", "Program", "Ocena", "Urządzenie", "Data", "Czas trwania"];

type Props = {
  data: MassageHistoryInterface;
};

const ReportTableRow: React.FC<Props> = ({
  data: { user, program, rating, deviceSerialNumber, startDate, massageTime },
}) => {
  return (
    <TableRow>
      <td>{user.name}</td>
      <td>{program.name}</td>
      <td>
        <AppStars starsValue={rating} />
      </td>
      <td>{deviceSerialNumber}</td>
      <td>
        {getDate(startDate)}
        <span className="ms-2"> {getTime(startDate)}</span>
      </td>
      <td>{getDuration(massageTime)}</td>
    </TableRow>
  );
};

export default ReportTableRow;
