import TableRow from "common/components/TableRow";
import { getDate, getTime } from "common/utils/time";
import { ReportInterface } from "../../../../shared/interfaces/report";

type Props = {
  data: ReportInterface;
};

const IssuesTableRow: React.FC<Props> = ({ data: { userEmail, userName, date, deviceSerialNumber, description } }) => {
  return (
    <TableRow>
      <td>{userName}</td>
      <td>{userEmail}</td>
      <td>
        {getDate(date)}
        <span className="ms-2">{getTime(date)}</span>
      </td>
      <td>{deviceSerialNumber}</td>
      <td>
        <div className="user-comment">{description}</div>
      </td>
    </TableRow>
  );
};

export default IssuesTableRow;
