import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import AppButton, { ButtonColorTypes } from "common/components/AppButton";
import ModuleContentContainer from "common/components/ModuleContentContainer";
import ModuleHeader from "common/components/ModuleHeader";
import PageWrapper from "common/components/PageWrapper";
import Table from "common/components/Table";
import { AppRoutesEnum } from "common/types";
import { UserFilteringDataInterface } from "../../../../shared/interfaces/user";
import { useLazyGetUsersQuery } from "./userApi";
import UserFilters from "./UserFilters";
import UsersTableRow from "./UsersTableRow";
import Pagination from "common/components/Pagination";

const USERS_HEADERS = ["Imię i nazwisko", "Email", "Rola", "Ostatnia aktywność"];

const Users: React.FC = () => {
  const navigate = useNavigate();
  const [filteringData, setFilteringData] = useState<UserFilteringDataInterface>({});
  const [getUsers, { data: users, isLoading }] = useLazyGetUsersQuery();

  useEffect(() => {
    getUsers(filteringData);
  }, [getUsers, filteringData]);

  const handleNavigateToAddAdmin = () => {
    navigate(AppRoutesEnum.AddAdmin);
  };

  const handleNavigateToUserDetails = (id: string) => {
    navigate(`${AppRoutesEnum.UserDetails}${id}`);
  };

  const renderRows = () => {
    if (!users) return null;
    return users.documents.map((d) => {
      return <UsersTableRow data={d} key={d._id} onClick={() => handleNavigateToUserDetails(d._id)} />;
    });
  };

  return (
    <PageWrapper>
      <ModuleHeader
        title="Lista użytkowników"
        extraButtons={
          <>
            <AppButton
              title="Dodaj administratora"
              color={ButtonColorTypes.PRIMARY}
              onClick={handleNavigateToAddAdmin}
            />
          </>
        }
      />
      <ModuleContentContainer>
        <UserFilters filteringData={filteringData} onChange={setFilteringData} />
        <Table headers={USERS_HEADERS} noDataMsg="Brak zdarzeń" rows={renderRows()} isLoading={isLoading} />
        <Pagination filteringData={filteringData} onChange={setFilteringData} total={_.get(users, "total", 1)} />
      </ModuleContentContainer>
    </PageWrapper>
  );
};

export default Users;
