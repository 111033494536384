import Logo from "../../assets/images/logo.svg";
import HamburgerMenu from "../../assets/images/hamburger-menu.svg";
import { Link, useLocation } from "react-router-dom";
import { AppRoutesEnum } from "common/types";
import { logout } from "common/utils/logout";

type HeaderLinkProps = {
  title: string;
  route: string;
  additionalAction?: () => void;
};

const HeaderLink = ({ title, route, additionalAction }: HeaderLinkProps) => {
  const location = useLocation();

  const isSelectedRoute = () => {
    return location.pathname === route;
  };

  return (
    <li className={`header-item ${isSelectedRoute() && "selected"}`}>
      <Link
        className="header-link waves-effect waves-dark header-link"
        to={route}
        aria-expanded="false"
        onClick={additionalAction}
      >
        <span className="hide-menu">{title}</span>
      </Link>
    </li>
  );
};

type HeaderProps = {
  showMenu: boolean;
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

const Header = ({ showMenu, setShowMenu }: HeaderProps) => {
  const handleLogout = () => {
    logout();
  };

  return (
    <header className="topbar">
      <nav className="navbar top-navbar navbar-expand-md navbar-light">
        <div className="navbar-header">
          <Link className="navbar-brand" to="reports">
            <span className="logo-text">
              <img src={Logo} alt="homepage" className="dark-logo" />
            </span>
          </Link>
          <div className="nav-toggler waves-effect waves-light d-block d-lg-none">
            <img
              src={HamburgerMenu}
              alt="hamburger"
              style={{ zIndex: 1, position: "absolute" }}
              onClick={() => setShowMenu(!showMenu)}
            />
          </div>
        </div>
        <ul id="headernav">
          <HeaderLink title="Raporty" route={AppRoutesEnum.Reports} />
          <HeaderLink title="Użytkownicy" route={AppRoutesEnum.Users} />
          <HeaderLink title="Zgłoszenia" route={AppRoutesEnum.Issues} />
          <HeaderLink title="Masaże" route={AppRoutesEnum.Massages} />
          <HeaderLink title="Wyloguj" route="/login" additionalAction={handleLogout} />
        </ul>
      </nav>
    </header>
  );
};

export default Header;
