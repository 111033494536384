import _ from "lodash";
import { useEffect, useState } from "react";
import ReactSelect from "react-select";

export type OptionType = {
  value: string;
  label: string;
  isDisabled?: boolean;
};

type Props = {
  options: OptionType[];
  value?: string;
  error?: string;
  onSelect: Function;
};

const customStyles = {
  container: () => ({
    border: "1px solid #ecf0f2",
    borderRadius: "10px",
  }),
  control: (provided: any) => ({
    ...provided,
    border: "none",
    borderRadius: "10px",
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 9999,
    marginBottom: "40px",
  }),
};

const AppSelect: React.FC<Props> = ({ value, error, options, onSelect }) => {
  const [currentSelectedValue, setCurrentSelectedValue] = useState<OptionType>();

  useEffect(() => {
    setCurrentSelectedValue(_.find(options, { value: value }));
  }, [value]);

  return (
    <div className={`col-md-12 position-relative`}>
      <ReactSelect
        options={options}
        styles={customStyles}
        placeholder="Wybierz"
        onChange={(data) => onSelect(data?.value, currentSelectedValue?.value)}
        value={currentSelectedValue}
      />

      {error && <div className="invalid-tooltip">{error}</div>}
    </div>
  );
};

export default AppSelect;
