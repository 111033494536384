export enum AppRoutesEnum {
  Login = "/login",
  Reports = "/reports",
  Users = "/users",
  UserDetails = "/users/user/",
  AddAdmin = "/users/addAdmin",
  Massages = "/massages",
  EditMassage = "/massages/massage/",
  AddMassage = "/massages/massage",
  Issues = "/issues",
}

export enum AllowedEngineCycleInputsEnum {
  Time = "time",
  Power = "power",
  SmoothTransition = "smoothTransition",
}
