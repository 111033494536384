import { BaseInterface } from "./base";

export interface ReportInterface extends BaseInterface {
  userId: string;
  userEmail: string;
  userName: string;
  date: Date;
  deviceSerialNumber: string;
  description: string;
}

export interface CreateReportRequestInterface {
  description: string;
  deviceSerialNumber?: string;
  date: number;
}

export interface ReportFilteringDataInterface {
  userName?: string;
  userEmail?: string;
  deviceSerialNumber?: string;
  "createdAt[gte]"?: string;
  "createdAt[lte]"?: string;
  offset?: number;
  limit?: number;
}

export enum ReportFilterTypesEnum {
  USER_NAME = "userName",
  USER_EMAIL = "userEmail",
  DEVICE_SERIAL_NUMBER = "deviceSerialNumber",
  CREATED_AT_GTE = "createdAt[gte]",
  CREATED_AT_LTE = "createdAt[lte]",
}
