import { useEffect, useRef } from "react";

export enum InputTypes {
  TEXT = "text",
  NUMBER = "number",
  EMAIL = "email",
  PASSWORD = "password",
}

type Props = {
  inputType: InputTypes;
  value: string | ReadonlyArray<string> | number | undefined;
  onChange: React.Dispatch<React.SetStateAction<any>>;
  error?: string;
  placeholder?: string;
  innerLabel?: string;
  disabled?: boolean;
};

const AppInput: React.FC<Props> = ({ inputType, value, onChange, error, placeholder, innerLabel, disabled }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!value && inputRef.current !== null) {
      inputRef.current.value = "";
    }
  }, [value]);

  return (
    <div className={`col-md-12 position-relative ${innerLabel && "input-group"}`}>
      <input
        ref={inputRef}
        type={inputType}
        className="form-control error-form"
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
      />
      {innerLabel && <span className="input-group-text">{innerLabel}</span>}
      {error && <div className="invalid-tooltip">{error}</div>}
    </div>
  );
};

export default AppInput;
