import { createApi } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "app/apiConfig";
import {
  BaseCreateResponseInterface,
  BaseDocumentResponseInterface,
  BaseListResponseInterface,
  BaseSuccessResponse,
} from "../../../../shared/interfaces/base";
import {
  CreateUserRequestInterface,
  DeleteUserRequestInterface,
  UpdateUserRequestInterface,
  UserFilteringDataInterface,
  UserInterface,
} from "../../../../shared/interfaces/user";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: customBaseQuery,
  tagTypes: ["User"],
  endpoints: (build) => ({
    createAdmin: build.mutation<BaseCreateResponseInterface<UserInterface>, CreateUserRequestInterface>({
      query: (data) => ({
        url: `user/user`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["User"],
    }),
    getUsers: build.query<BaseListResponseInterface<UserInterface>, UserFilteringDataInterface>({
      query: (query) => ({
        url: `user/user`,
        params: query,
      }),
      providesTags: ["User"],
    }),
    updateUser: build.mutation<BaseCreateResponseInterface<UserInterface>, UpdateUserRequestInterface>({
      query: (data) => ({
        url: `user/user/${data._id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["User"],
    }),
    getUser: build.query<BaseDocumentResponseInterface<UserInterface>, string>({
      query: (id) => ({
        url: `user/user/${id}`,
      }),
    }),
    deleteUser: build.mutation<BaseSuccessResponse, DeleteUserRequestInterface>({
      query: ({ id }) => ({
        url: `user/user/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useCreateAdminMutation,
  useLazyGetUsersQuery,
  useLazyGetUserQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = userApi;
