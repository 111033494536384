import { useEffect, useState } from "react";
import _ from "lodash";
import ModuleContentContainer from "common/components/ModuleContentContainer";
import ModuleHeader from "common/components/ModuleHeader";
import PageWrapper from "common/components/PageWrapper";
import Table from "common/components/Table";
import { ReportFilteringDataInterface } from "../../../../shared/interfaces/report";
import IssuesTableRow from "./IssuesTableRow";
import IssueFilters from "./IssueFilters";
import Pagination from "common/components/Pagination";
import { useLazyGetIssuesQuery } from "./issueApi";

export const ISSUES_HEADERS = ["Imię i nazwisko", "Email", "Data zgłoszenia", "Urządzenie", "Opis"];

const Issues: React.FC = () => {
  const [filteringData, setFilteringData] = useState<ReportFilteringDataInterface>({});
  const [getIssues, { data: issues, isLoading }] = useLazyGetIssuesQuery();

  useEffect(() => {
    getIssues(filteringData);
  }, [getIssues, filteringData]);

  const renderRows = () => {
    if (!issues) return null;
    return issues.documents.map((d) => {
      return <IssuesTableRow data={d} key={d._id} />;
    });
  };

  return (
    <PageWrapper>
      <ModuleHeader title="Zgłoszenia" />
      <ModuleContentContainer>
        <IssueFilters filteringData={filteringData} onChange={setFilteringData} />
        <Table headers={ISSUES_HEADERS} noDataMsg="Brak zgłoszeń" rows={renderRows()} isLoading={isLoading} />
        <Pagination filteringData={filteringData} onChange={setFilteringData} total={_.get(issues, "total", 1)} />
      </ModuleContentContainer>
    </PageWrapper>
  );
};

export default Issues;
