import StarIcon from "../../assets/images/rating-star.svg";

type Props = {
  starsValue: number;
};

const AppStars: React.FC<Props> = ({ starsValue }) => {
  const generateStars = () => {
    if (!starsValue) {
      return <span className="no-rating text-muted">Brak oceny</span>;
    }

    const stars = [];
    for (let i = 0; i < starsValue; i++) {
      stars.push(<img key={`single-star-${i}`} src={StarIcon} alt={`star-${i}`} />);
    }

    return (
      <>
        <span className="program-rating float-start me-2">{starsValue}</span>
        {stars}
      </>
    );
  };

  return <div className="stars d-flex flex-nowrap">{generateStars()}</div>;
};

export default AppStars;
