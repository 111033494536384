import moment from "moment";

export const getDate = (date: Date) => {
  return moment(date).format("DD.MM.YYYY");
};

export const getTime = (date: Date) => {
  return moment(date).format("HH:mm");
};

export const getDuration = (duration: number) => {
  return `${moment.duration(duration).hours() > 0 ? moment.duration(duration).hours() + "h" : ""} 
  ${moment.duration(duration).minutes() > 0 ? moment.duration(duration).minutes() + "min" : ""} 
  ${moment.duration(duration).seconds() > 0 ? moment.duration(duration).seconds() + "s" : ""} 
  `;
};

export const getSecondsDuration = (seconds: number) => {
  return `${
    parseInt(
      Math.floor(seconds / 3600)
        .toString()
        .padStart(2, "0"),
    ) > 0
      ? Math.floor(seconds / 3600)
          .toString()
          .padStart(2, "0") + "h"
      : ""
  } 
  ${
    parseInt(
      Math.floor((seconds % 3600) / 60)
        .toString()
        .padStart(2, "0"),
    ) > 0
      ? Math.floor((seconds % 3600) / 60)
          .toString()
          .padStart(2, "0") + "min"
      : ""
  } 
  ${
    parseInt(
      Math.floor(seconds % 60)
        .toString()
        .padStart(2, "0"),
    ) > 0
      ? Math.floor(seconds % 60)
          .toString()
          .padStart(2, "0") + "s"
      : ""
  } 
  `;
};
