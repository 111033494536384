export enum ButtonColorTypes {
  PRIMARY = "btn-primary",
  SECONDARY = "btn-secondary",
  LIGHT = "btn-light",
  DESTRUCTIVE = "btn-outline-danger",
}

type Props = {
  color: ButtonColorTypes;
  title: string;
  customClass?: string;
  onClick?: () => void;
};

const AppButton: React.FC<Props> = ({ color, title, customClass, onClick }) => {
  return (
    <div className={`btn ${color} ${customClass || ""}`} onClick={onClick}>
      {title}
    </div>
  );
};

export default AppButton;
