type Props = {
  children: React.ReactNode;
  onClick?: any
};

const TableRow: React.FC<Props> = ({ children, onClick }) => {
  return <tr onClick={onClick}>{children}</tr>;
};

export default TableRow;
