import _ from "lodash";
import moment from "moment";
import { DayNameEnum, UserActivityInterface } from "../../../../shared/interfaces/user";

type Props = {
  title: string;
  data?: UserActivityInterface[];
};

type Keys = keyof UserActivityInterface;

const UserTimeSheet: React.FC<Props> = ({ title, data }) => {
  const renderHours = (day: DayNameEnum, field: Keys) => {
    const hoursData = _.find(data, (d) => d.dayName === day);
    if (hoursData) {
      return moment(hoursData[field]).format("HH:mm");
    }
    return "-";
  };

  return (
    <div className="col-12 d-flex flex-column col-lg-3 hours-data">
      <h5 className="text-center">{title}</h5>
      <div className="form-group d-flex align-items-center gap-2">
        <span className="weekday">Pon</span>
        <span className="form-content">{renderHours(DayNameEnum.MON, "hourFrom")}</span> -
        <span className="form-content">{renderHours(DayNameEnum.MON, "hourTo")}</span>
      </div>
      <div className="form-group d-flex align-items-center gap-2">
        <span className="weekday">Wt</span>
        <span className="form-content">{renderHours(DayNameEnum.TUE, "hourFrom")}</span> -
        <span className="form-content">{renderHours(DayNameEnum.TUE, "hourTo")}</span>
      </div>
      <div className="form-group d-flex align-items-center gap-2">
        <span className="weekday">Śr</span>
        <span className="form-content">{renderHours(DayNameEnum.WED, "hourFrom")}</span> -
        <span className="form-content">{renderHours(DayNameEnum.WED, "hourTo")}</span>
      </div>
      <div className="form-group d-flex align-items-center gap-2">
        <span className="weekday">Czw</span>
        <span className="form-content">{renderHours(DayNameEnum.THU, "hourFrom")}</span> -
        <span className="form-content">{renderHours(DayNameEnum.THU, "hourTo")}</span>
      </div>
      <div className="form-group d-flex align-items-center gap-2">
        <span className="weekday">Pt</span>
        <span className="form-content">{renderHours(DayNameEnum.FRI, "hourFrom")}</span> -
        <span className="form-content">{renderHours(DayNameEnum.FRI, "hourTo")}</span>
      </div>
      <div className="form-group d-flex align-items-center gap-2">
        <span className="weekday">Sob</span>
        <span className="form-content">{renderHours(DayNameEnum.SAT, "hourFrom")}</span> -
        <span className="form-content">{renderHours(DayNameEnum.SAT, "hourTo")}</span>
      </div>
      <div className="form-group d-flex align-items-center gap-2">
        <span className="weekday">Nd</span>
        <span className="form-content">{renderHours(DayNameEnum.SUN, "hourFrom")}</span> -
        <span className="form-content">{renderHours(DayNameEnum.SUN, "hourTo")}</span>
      </div>
    </div>
  );
};

export default UserTimeSheet;
