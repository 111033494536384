import { createApi } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "app/apiConfig";
import { BaseListResponseInterface } from "../../../../shared/interfaces/base";
import { ReportInterface, ReportFilteringDataInterface } from "../../../../shared/interfaces/report";

export const issueApi = createApi({
  reducerPath: "issueApi",
  baseQuery: customBaseQuery,
  tagTypes: ["Issue"],
  endpoints: (build) => ({
    getIssues: build.query<BaseListResponseInterface<ReportInterface>, ReportFilteringDataInterface>({
      query: (query) => ({
        url: `report/report`,
        params: query,
      }),
      providesTags: ["Issue"],
    }),
  }),
});

export const { useLazyGetIssuesQuery } = issueApi;
