import TableRow from "common/components/TableRow";
import { AppRoutesEnum } from "common/types";
import { getSecondsDuration } from "common/utils/time";
import { useNavigate } from "react-router-dom";
import { MassageGroupEnum, MassageInterface } from "../../../../shared/interfaces/massage";

type Props = {
  data: Partial<MassageInterface>;
};

const MassagesTableRow: React.FC<Props> = ({
  data: { _id, name, description, icon, massageTime, massageGroup, active },
}) => {
  const navigate = useNavigate();

  const renderGroup = () => {
    switch (massageGroup) {
      case MassageGroupEnum.GROUP_1:
        return "Energetyczne";
      case MassageGroupEnum.GROUP_2:
        return "Zdrowotne";
      case MassageGroupEnum.GROUP_3:
        return "Relaksasyjne";
      case MassageGroupEnum.GROUP_4:
        return "Regenerujące";
      default:
        return "-";
    }
  };

  const navigateToEditMassage = () => {
    navigate(`${AppRoutesEnum.EditMassage}${_id}`);
  };

  return (
    <TableRow onClick={navigateToEditMassage}>
      <td className="status">
        {!active && <span className="inactive"></span>}
        {active && <span className="active"></span>}
      </td>
      <th scope="row">
        <img src={icon} className="program-cover" alt="program icon" />
      </th>
      <td>{name}</td>
      <td>{renderGroup()}</td>
      <td>{massageTime ? getSecondsDuration(massageTime) : "-"}</td>
      <td>
        <div>{description}</div>
      </td>
    </TableRow>
  );
};

export default MassagesTableRow;
