import { EngineEnum } from "../../../shared/interfaces/massage";
import { OptionType } from "./components/AppSelect";

export const MOTORS: OptionType[] = [
  { label: "Silnik 1", value: EngineEnum.ONE_MOTOR },
  { label: "Silnik 2", value: EngineEnum.TWO_MOTOR },
  { label: "Silnik 3", value: EngineEnum.THREE_MOTOR },
  { label: "Silnik 4", value: EngineEnum.FOUR_MOTOR },
  { label: "Silnik 5", value: EngineEnum.FIVE_MOTOR },
  { label: "Silnik 6", value: EngineEnum.SIX_MOTOR },
  { label: "Silnik 7", value: EngineEnum.SEVEN_MOTOR },
  { label: "Silnik 8", value: EngineEnum.EIGHT_MOTOR },
  { label: "Silnik 9", value: EngineEnum.NINE_MOTOR },
  { label: "Silnik 10", value: EngineEnum.TEN_MOTOR },
  { label: "Silnik 11", value: EngineEnum.ELEVEN_MOTOR },
  { label: "Silnik 12", value: EngineEnum.TWELVE_MOTOR },
  { label: "Silnik 13", value: EngineEnum.THIRTEEN_MOTOR },
  { label: "Silnik 14", value: EngineEnum.FOURTEEN_MOTOR },
  { label: "Silnik 15", value: EngineEnum.FIFTEEN_MOTOR },
  { label: "Silnik 16", value: EngineEnum.SIXTEEN_MOTOR },
];
