import moment from "moment";
import AppButton, { ButtonColorTypes } from "common/components/AppButton";
import AppDatePicker from "common/components/AppDatePicker";
import AppInput, { InputTypes } from "common/components/AppInput";
import LabeledContainer from "common/components/LabeledContainer";
import {
  MassageHistoryFilteringDataInterface,
  MassageHistoryFilterTypesEnum,
} from "../../../../shared/interfaces/massage-history";

type Props = {
  filteringData: MassageHistoryFilteringDataInterface;
  onChange: React.Dispatch<React.SetStateAction<MassageHistoryFilteringDataInterface>>;
};

const ReportFilters: React.FC<Props> = ({ filteringData, onChange }) => {
  const handleChangingSingleField = (fieldName: MassageHistoryFilterTypesEnum, value: string): void => {
    const filterDataTmp = { ...filteringData };
    filterDataTmp[fieldName] = value;
    onChange(filterDataTmp);
  };

  const handleClearFilters = (): void => {
    onChange({});
  };

  const handleFilterByDate = (date: Date): void => {
    const filterDataTmp = { ...filteringData };
    filterDataTmp[MassageHistoryFilterTypesEnum.END_DATE_GTE] = moment(date).startOf("day").unix().toString();
    filterDataTmp[MassageHistoryFilterTypesEnum.END_DATE_LTE] = moment(date).endOf("day").unix().toString();
    onChange(filterDataTmp);
  };

  const currentEndDateValue = (): Date | undefined => {
    if (filteringData["endDate[gte]"]) {
      return new Date(parseInt(filteringData["endDate[gte]"]) * 1000);
    }
    return undefined;
  };

  return (
    <div className="pb-3" id="filtry">
      <div className="col-12 col-md-4 col-lg-3 d-flex w-100 gap-2">
        <div className="form-group col-12 col-lg-2">
          <LabeledContainer title="Użytkownik">
            <AppInput
              inputType={InputTypes.TEXT}
              value={filteringData["user.name"]}
              onChange={(text: string) => {
                handleChangingSingleField(MassageHistoryFilterTypesEnum.USER_NAME, text);
              }}
            />
          </LabeledContainer>
        </div>
        <div className="form-group col-12 col-lg-2">
          <LabeledContainer title="Nazwa programu">
            <AppInput
              inputType={InputTypes.TEXT}
              value={filteringData["program.name"]}
              onChange={(text: string) => {
                handleChangingSingleField(MassageHistoryFilterTypesEnum.PROGRAM_NAME, text);
              }}
            />
          </LabeledContainer>
        </div>
        <div className="form-group col-12 col-lg-2">
          <LabeledContainer title="Numer urządzenia">
            <AppInput
              inputType={InputTypes.TEXT}
              value={filteringData.deviceSerialNumber}
              onChange={(text: string) => {
                handleChangingSingleField(MassageHistoryFilterTypesEnum.DEVICE_SERIAL_NUMBER, text);
              }}
            />
          </LabeledContainer>
        </div>
        <div className="form-group col-12 col-lg-2">
          <LabeledContainer title="Data zgłoszenia">
            <AppDatePicker
              value={currentEndDateValue()}
              onChange={(date: Date) => {
                handleFilterByDate(date);
              }}
            />
          </LabeledContainer>
        </div>
        <div className="float-right mt-4 ms-auto">
          <AppButton title="Usuń filtry" onClick={handleClearFilters} color={ButtonColorTypes.LIGHT} />
        </div>
      </div>
    </div>
  );
};

export default ReportFilters;
