import AppButton, { ButtonColorTypes } from "common/components/AppButton";
import AppInput, { InputTypes } from "common/components/AppInput";
import AppLoader from "common/components/AppLoader";
import { AppRoutesEnum } from "common/types";
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../assets/images/logo.svg";
import { useAdminLoginMutation } from "./loginApi";

const Login = () => {
  const navigate = useNavigate();
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [adminLogin, { error, data, isLoading }] = useAdminLoginMutation();

  const handleLogin = useCallback(async () => {
    try {
      if (login && password) {
        await adminLogin({ login, password });
      }
    } catch (err) {
      console.error(err);
    }
  }, [adminLogin, login, password]);

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        handleLogin();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [handleLogin]);

  useEffect(() => {
    if (data?.success) {
      localStorage.setItem("token", data.token);
      navigate(AppRoutesEnum.Reports);
    }
  }, [data, navigate]);

  useEffect(() => {
    if (error) {
      toast.error("Wystąpił błąd");
    }
  }, [error]);

  return (
    <div className="login-wrapper">
      <div className="login-container">
        <div className="col-12 col-md-6 login-decor">
          <img src={Logo} alt="logo" />
          <p>Przejmij kontrolę</p>
        </div>
        <div className="col-12 col-md-6 d-flex flex-column p-5 justify-content-center">
          <h1>Zaloguj się</h1>
          <p className="text-muted fw-light">jeśli nie masz konta, skontaktuj się z administracją.</p>
          <div className="form-group">
            <div className="col-md-12">
              <AppInput placeholder="Adres e-mail" inputType={InputTypes.EMAIL} value={login} onChange={setLogin} />
            </div>
          </div>
          <div className="form-group">
            <div className="col-md-12">
              <AppInput
                placeholder="Wpisz hasło"
                inputType={InputTypes.PASSWORD}
                value={password}
                onChange={setPassword}
              />
            </div>
          </div>
          <div className="login-button">
            <AppLoader show={isLoading} />
            {!isLoading && <AppButton title="Zaloguj" onClick={handleLogin} color={ButtonColorTypes.PRIMARY} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
