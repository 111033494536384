import _ from "lodash";
import AppButton, { ButtonColorTypes } from "common/components/AppButton";
import AppInput, { InputTypes } from "common/components/AppInput";
import AppSelect, { OptionType } from "common/components/AppSelect";
import LabeledContainer from "common/components/LabeledContainer";
import { AllowedEngineCycleInputsEnum } from "common/types";
import { EngineEnum, EngineSettingInterface, MassageSettingInterface } from "../../../../shared/interfaces/massage";

type Props = {
  setting: MassageSettingInterface;
  setMotor: (motorIndex: number, newVal: EngineEnum, oldVal: EngineEnum) => void;
  motorIndex: number;
  setCycleInput: (motorIndex: number, cycleIndex: number, value: number, field: AllowedEngineCycleInputsEnum) => void;
  addCycle: (motorIndex: number) => void;
  removeCycle: (motorIndex: number, cycleIndex: number) => void;
  motorsToSelect: OptionType[];
  error?: Object;
};

const MotorSettings: React.FC<Props> = ({
  setting,
  setMotor,
  motorIndex,
  setCycleInput,
  addCycle,
  removeCycle,
  motorsToSelect,
  error,
}) => {
  const renderCycleInputs = () => {
    return setting.engineSettings.map((cycle: EngineSettingInterface, cycleIndex: number) => {
      const canAdd: boolean = cycleIndex === setting.engineSettings?.length - 1;
      const canRemove: boolean = motorIndex === 0 ? cycleIndex !== 0 : true;

      return (
        <div className="col-12 col-md-12 col-lg-9 row m-0" key={cycleIndex}>
          <div className="col-12 col-md-6 col-lg-4">
            <LabeledContainer required={true} title="Czas pracy">
              <AppInput
                inputType={InputTypes.NUMBER}
                innerLabel="sekund"
                onChange={(value: string) =>
                  setCycleInput(motorIndex, cycleIndex, parseInt(value), AllowedEngineCycleInputsEnum.Time)
                }
                value={cycle.time}
                error={_.get(error, `engineSettings[${cycleIndex}].time`)}
              />
            </LabeledContainer>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <LabeledContainer required={true} title="Moc docelowa">
              <AppInput
                inputType={InputTypes.NUMBER}
                innerLabel="%"
                onChange={(value: string) =>
                  setCycleInput(motorIndex, cycleIndex, parseInt(value), AllowedEngineCycleInputsEnum.Power)
                }
                value={cycle.power}
                error={_.get(error, `engineSettings[${cycleIndex}].power`)}
              />
            </LabeledContainer>
          </div>
          <div className="col-12 col-md-12 col-lg-4 d-flex justify-content-between">
            <div className="d-flex align-items-center ms-4 gap-2">
              <LabeledContainer title="Płynne przejście">
                <AppInput
                  inputType={InputTypes.NUMBER}
                  onChange={(value: string) =>
                    setCycleInput(
                      motorIndex,
                      cycleIndex,
                      parseFloat(value),
                      AllowedEngineCycleInputsEnum.SmoothTransition,
                    )
                  }
                  value={cycle.smoothTransition || 0}
                  error={_.get(error, `engineSettings[${cycleIndex}].smoothTransition`)}
                />
              </LabeledContainer>
              {canAdd && (
                <AppButton
                  customClass="mt-2"
                  title="+"
                  onClick={() => addCycle(motorIndex)}
                  color={ButtonColorTypes.PRIMARY}
                />
              )}
              {canRemove && (
                <AppButton
                  customClass="mt-2"
                  title="-"
                  onClick={() => removeCycle(motorIndex, cycleIndex)}
                  color={ButtonColorTypes.SECONDARY}
                />
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <hr />
      <div className="col-12 col-md-12 col-lg-3 p-0">
        <LabeledContainer required={true} title="Wybierz silnik">
          <AppSelect
            options={motorsToSelect}
            onSelect={(newVal: EngineEnum, oldVal: EngineEnum) => setMotor(motorIndex, newVal, oldVal)}
            value={setting.engineNumber}
            error={_.get(error, "engineNumber")}
          />
        </LabeledContainer>
      </div>
      {renderCycleInputs()}
    </>
  );
};

export default MotorSettings;
