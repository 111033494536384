import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pl from "date-fns/locale/pl";

registerLocale("pl", pl);

type Props = {
  value: Date | undefined;
  onChange: React.Dispatch<React.SetStateAction<any>>;
  error?: string;
};

const AppDatePicker: React.FC<Props> = ({ value, onChange, error }) => {
  return (
    <div className={`col-12`}>
      <DatePicker
        dateFormat="dd/MM/yyyy"
        locale="pl"
        className="form-control form-control-line"
        selected={value}
        onChange={(date: Date) => onChange(date)}
      />
      {error && <div className="invalid-tooltip">{error}</div>}
    </div>
  );
};

export default AppDatePicker;
